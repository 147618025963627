import React from 'react';
import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig';
import { protectedRoutes, publicRoutes, adminRoutes } from 'configs/RoutesConfig';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import AppRoute from './AppRoute';
import { useSelector } from 'react-redux';

const Routes = () => {
	const { token, user } = useSelector(state => state.auth || {}); 
	const isAdmin = user?.role === 'admin';

	return (
		<RouterRoutes>
			<Route path="/" element={<ProtectedRoute />}>
				<Route path="/" element={<Navigate replace to={AUTHENTICATED_ENTRY} />} />

				{/* Protected Routes for All Authenticated Users */}
				{protectedRoutes.map((route, index) => (
					<Route 
						key={route.key + index} 
						path={route.path}
						element={
							<AppRoute
								routeKey={route.key} 
								component={route.component}
								{...route.meta} 
							/>
						}
					/>
				))}

				{/* Admin-Specific Routes */}
				{isAdmin && adminRoutes.map((route, index) => (
					<Route 
						key={route.key + index} 
						path={route.path}
						element={
							<AppRoute
								routeKey={route.key} 
								component={route.component}
								{...route.meta} 
							/>
						}
					/>
				))}

				<Route path="*" element={<Navigate to="/" replace />} />
			</Route>

			<Route path="/" element={<PublicRoute />}>
				{publicRoutes.map(route => (
					<Route 
						key={route.path} 
						path={route.path}
						element={
							<AppRoute
								routeKey={route.key} 
								component={route.component}
								{...route.meta} 
							/>
						}
					/>
				))}
			</Route>
		</RouterRoutes>
	)
}

export default Routes;
