import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { signOutSuccess } from 'store/slices/authSlice';
import { notification } from 'antd';
import { store } from '../store';
import { AUTH_TOKEN } from 'constants/AuthConstant';

const unauthorizedCode = [400, 401, 403, 419]; // Added 419 here
let sessionExpiredNotificationShown = false; // Flag to track if session expiration notification is shown

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

// Config
const TOKEN_PAYLOAD_KEY = 'Authorization';

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;

    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
    }

    return config;
  },
  (error) => {
    notification.error({
      message: 'Error',
      description: 'There was an error with the request.',
    });
    return Promise.reject(error);
  }
);

// API Response interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    let notificationParam = {
      message: '',
      description: '',
    };

    // Handle unauthorized errors
    if (unauthorizedCode.includes(error.response.status) && !sessionExpiredNotificationShown) {
      sessionExpiredNotificationShown = true;

      if (error.response.status === 419) {
        notificationParam.message = 'Session Expired';
        notificationParam.description = 'Your session has expired. Please log in again.';
      } else {
        notificationParam.message = 'Is your login correct?';
        notificationParam.description = 'Please check your login and try again.';
      }

      // Remove token and log out
      localStorage.removeItem(AUTH_TOKEN);
      store.dispatch(signOutSuccess());
    }

    // Handle specific error status codes
    if (error.response.status === 404) {
      notificationParam.message = 'Not Found';
      notificationParam.description = 'The requested resource could not be found.';
    }

    if (error.response.status === 500) {
      notificationParam.message = 'Internal Server Error';
      notificationParam.description = 'An error occurred on the server. Please try again later.';
    }

    if (error.response.status === 508) {
      notificationParam.message = 'Time Out';
      notificationParam.description = 'The server took too long to respond. Please try again later.';
    }

    // Show notification if it's set
    if (notificationParam.message) {
      notification.error(notificationParam);
    }

    return Promise.reject(error);
  }
);

export default service;
