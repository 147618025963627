import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import AuthService from 'services/AuthService';
import { getUserInfo } from 'services/api';
import productReducer, { fetchProducts } from './productSlice';
import { injectReducer } from 'store'; 

let sessionExpiredNotificationShown = false;

export const resetSessionNotificationFlag = () => {
	sessionExpiredNotificationShown = false;
  };

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: '',
	user: null
}

export const signIn = createAsyncThunk('auth/signIn', async ({ email, username, password, isAdmin = false }, thunkAPI) => {
	const { dispatch, rejectWithValue } = thunkAPI;
	
	try {
		const response = isAdmin 
			? await AuthService.adminLogin({ username, password })
			: await AuthService.login({ email, password });
		const token = response.token;

		if (!token) throw new Error('Token not found in response');

		localStorage.setItem(AUTH_TOKEN, token);

		if (isAdmin) {
			dispatch(signInSuccess({ token, user: { role: 'admin' } }));  // Set a simple user object for admin
			return { token, user: { role: 'admin' }, redirect: '/admin/dashboard' };
		}

		const userInfo = await getUserInfo();

		dispatch(signInSuccess({ token, user: userInfo }));
		injectReducer('products', productReducer);
		dispatch(fetchProducts());
  
		return { token, user: userInfo };
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Login failed');
	}
});

export const signUp = createAsyncThunk('auth/signUp',async (data, { rejectWithValue }) => {
	const { email, password } = data
	try {
		const response = await FirebaseService.signUpEmailRequest(email, password)
		if (response.user) {
			const token = response.user.refreshToken;
			localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
			return token;
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
})

export const signOut = createAsyncThunk('auth/signOut',async () => {
    const response = await FirebaseService.signOutRequest()
	localStorage.removeItem(AUTH_TOKEN);
    return response.data
})

export const signInWithGoogle = createAsyncThunk('auth/signInWithGoogle', async (_, { rejectWithValue }) => {
    const response = await FirebaseService.signInGoogleRequest()
	if (response.user) {
		const token = response.user.refreshToken;
		localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
		return token;
	} else {
		return rejectWithValue(response.message?.replace('Firebase: ', ''));
	}
})

export const signInWithFacebook = createAsyncThunk('auth/signInWithFacebook', async (_, { rejectWithValue }) => {
    const response = await FirebaseService.signInFacebookRequest()
	if (response.user) {
		const token = response.user.refreshToken;
		localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
		return token;
	} else {
		return rejectWithValue(response.message?.replace('Firebase: ', ''));
	}
})


export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false;
			state.redirect = '/dashboard';
			state.token = action.payload.token;
			state.user = action.payload.user;
			state.showMessage = false;
			state.message = '';	
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			state.loading = false;
			state.token = null;
			resetSessionNotificationFlag(); 
			state.redirect = '/login';
			state.user = null;
		},
		showLoading: (state) => {
			state.loading = true
		},
		signInSuccess: (state, action) => {
			state.loading = false;
			state.redirect = '/dashboard';
			state.token = action.payload.token;
			state.user = action.payload.user;
			state.showMessage = false;
			state.message = '';	
			console.log("Signin success reducer", action.payload);
		},
		clearRedirect: (state) => {
            state.redirect = null;
        }
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				console.log("signIn pending");
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				console.log("signIn fulfilled with payload:", action.payload); // Log the full payload
				console.log("User information being set in state:", action.payload.user); // Log user info
			
				state.loading = false;
				state.redirect = '/dashboard';
				state.token = action.payload.token;
				state.user = action.payload.user;
				state.showMessage = false;
        		state.message = '';				
				console.log("State after setting user:", state.user); // Verify user is stored in state
			})
			
			.addCase(signIn.rejected, (state, action) => {
				console.log("signIn rejected with error:", action.payload); 
				state.loading = false;
				state.message = action.payload;
				state.showMessage = true;
				state.token = null;
				state.user = null;
			})
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false;
				state.token = null;
				state.user = null;
				state.redirect = '/';
				localStorage.removeItem(AUTH_TOKEN);
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signUp.pending, (state) => {
				state.loading = true
			})
			.addCase(signUp.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signUp.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithGoogle.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithGoogle.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signInWithGoogle.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithFacebook.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithFacebook.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signInWithFacebook.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
	},
})

export const { 
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess,
	clearRedirect
} = authSlice.actions

export default authSlice.reducer