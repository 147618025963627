import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { 
    UNAUTHENTICATED_ENTRY, 
    REDIRECT_URL_KEY 
} from 'configs/AppConfig';

const ProtectedRoute = () => {
    const { token, user } = useSelector(state => state.auth || {}); 
    const location = useLocation();
    const isAdmin = user?.role === 'admin';

    if (isAdmin && location.pathname === '/dashboard') {
        return <Navigate to="/admin/dashboard" replace />;
    }

    if (!token) {
        return <Navigate to={`${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`} replace />;
    }

    return <Outlet />;
};

export default ProtectedRoute;
