import service from 'auth/FetchInterceptor';

// Fetch user info using existing interceptor
export const getUserInfo = async () => {
  try {
    const response = await service.get('control/user-info'); 
    return response; 
  } catch (error) {
    console.error("Error fetching user info:", error);
    throw error;
  }
};

// Fetch products using existing interceptor
export const getProducts = async () => {
  try {
    const response = await service.get('control/products');
    return response; 
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};

export const getOnePages = async () => {
  try {
    const response = await service.get('control/onepage');
    return response; 
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};

export const getAlexaProducts = async () => {
  try {
    const response = await service.get('control/alexa');
    return response; 
  } catch (error) {
    console.error("Error fetching Alexa products:", error);
    throw error;
  }
};